:root {
  /* --color-blue: #1f2ef5; // ie --color-blue: #1f2ef5; */
  /* --color-coal: #0b1116; // ie --color-blue: #1f2ef5; */
  /* --color-pink: #f9e8d5; // ie --color-blue: #1f2ef5; */
  /* --color-black: #000; // ie --color-blue: #1f2ef5; */
  /* --color-white: #fff; // ie --color-blue: #1f2ef5; */
  /* --color-lightgray: #ceceee; // ie --color-blue: #1f2ef5; */
  /* --color-gray: #5b5765; // ie --color-blue: #1f2ef5; */
  /* --color-purple: #6c28a7; // ie --color-blue: #1f2ef5; */
  /* --color-yellow: #fab908; // ie --color-blue: #1f2ef5; */
  /* --color-red: #ff6b6c; // ie --color-blue: #1f2ef5; */
  /* --color-green: #66f285; // ie --color-blue: #1f2ef5; */
}

.w-reviewslider {
  margin-bottom: 0;
}
.w-reviewslider a {
  color: #fff;
}
.w-reviewslider .section-inset-y {
  padding-top: 10vw;
  padding-bottom: 10vw;
}

.w-reviewslider__headingtext {
  max-width: 450px;
}

.w-reviewslider__link {
  color: #fff;
}

.w-reviewslider__row {
  padding: 0 0 40px 0;
}

@media (min-width: 768px) {
  .w-reviewslider__row {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
  }
  .w-reviewslider__col--right {
    margin-left: 16.66666667%;
  }
}