:root {
  /* --color-blue: #1f2ef5; // ie --color-blue: #1f2ef5; */
  /* --color-coal: #0b1116; // ie --color-blue: #1f2ef5; */
  /* --color-pink: #f9e8d5; // ie --color-blue: #1f2ef5; */
  /* --color-black: #000; // ie --color-blue: #1f2ef5; */
  /* --color-white: #fff; // ie --color-blue: #1f2ef5; */
  /* --color-lightgray: #ceceee; // ie --color-blue: #1f2ef5; */
  /* --color-gray: #5b5765; // ie --color-blue: #1f2ef5; */
  /* --color-purple: #6c28a7; // ie --color-blue: #1f2ef5; */
  /* --color-yellow: #fab908; // ie --color-blue: #1f2ef5; */
  /* --color-red: #ff6b6c; // ie --color-blue: #1f2ef5; */
  /* --color-green: #66f285; // ie --color-blue: #1f2ef5; */
}

.w-newvideos {
  margin-bottom: 80px;
}
.w-newvideos h4 {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 800;
  line-height: 24px;
}
@media (min-width: 768px) {
  .w-newvideos h4 {
    margin-bottom: 40px;
    font-size: 32px;
    font-weight: 800;
    line-height: 32px;
  }
}

.w-newvideos__viewmore {
  color: #292233;
  font-size: 14px;
  margin-top: 16px;
}

.w-newvideos__videoscontainer {
  display: grid;
  column-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  overflow-x: auto;
}

.w-newvideos__item {
  position: relative;
  border-radius: 8px;
  height: 200px;
  display: flex;
  align-items: flex-end;
  aspect-ratio: 16/9;
  text-decoration: none;
}
.w-newvideos__item button {
  position: absolute;
  padding: 10px 20px;
  align-self: center;
  justify-self: center;
  left: calc(50% - 25px);
  z-index: 11;
  transition: all 0.3s;
}
@media (min-width: 1200px) {
  .w-newvideos__item {
    height: auto;
  }
}
@media (hover: hover) {
  .w-newvideos__item:hover {
    text-decoration: none;
  }
  .w-newvideos__item:hover .w-newvideos__overlay {
    opacity: 0.5;
  }
  .w-newvideos__item:hover button {
    transform: scale(1.3);
  }
}

.w-newvideos__title {
  font-weight: 500;
  position: relative;
  z-index: 11;
  display: inline-flex;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: #fff;
  padding: 0 12px 12px 12px;
}

.w-newvideos__views {
  position: absolute;
  bottom: 92px;
  z-index: 11;
  font-size: 12px;
  display: none;
}
@media (min-width: 768px) {
  .w-newvideos__views {
    display: block;
  }
}

.w-newvideos__overlay {
  background: linear-gradient(180deg, rgba(33, 27, 41, 0) 26%, #211B29 89.75%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  z-index: 10;
  pointer-events: none;
  transition: opacity 0.3s;
}