/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.ww-dialog {
  background: #fff;
  padding: 20px;
  max-width: 90vw;
  width: 600px;
  min-height: 100px;
  margin: 1.5em;
  margin-top: 60px;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}
.ww-dialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ww-dialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.ww-dialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.ww-dialog--video {
  padding: 0;
  position: relative;
  background: rgb(12.75, 12.75, 12.75);
}
.ww-dialog--video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.ww-dialog__modalbg {
  z-index: 99999;
  background: rgba(0, 0, 0, 0.85);
}
.ww-dialog__holder {
  overflow: hidden;
}
.ww-dialog__closebutton {
  width: 50px;
  height: 50px;
  background: #fff;
  position: absolute;
  display: block;
  z-index: 200;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  transition: background 0.3s;
  cursor: pointer;
  border: 1px solid #fff;
  right: 1.5em;
  left: auto;
  top: 6px;
}
@media (hover: hover) {
  .ww-dialog__closebutton:hover {
    background: #000;
  }
  .ww-dialog__closebutton:hover:before, .ww-dialog__closebutton:hover:after {
    background: #fff;
  }
}
.ww-dialog__closebutton:before, .ww-dialog__closebutton:after {
  content: "";
  width: 55%;
  height: 2px;
  background: #000;
  position: absolute;
  top: 48%;
  left: 22%;
  transform: rotate(45deg);
  transition: all 0.3s;
}
.ww-dialog__closebutton:after {
  transform: rotate(-45deg);
  transition: all 0.3s;
}

.ww-dialog.textvideo video {
  width: 100%;
  height: 100%;
}