/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
:root {
  /* --color-blue: #1f2ef5; // ie --color-blue: #1f2ef5; */
  /* --color-coal: #0b1116; // ie --color-blue: #1f2ef5; */
  /* --color-pink: #f9e8d5; // ie --color-blue: #1f2ef5; */
  /* --color-black: #000; // ie --color-blue: #1f2ef5; */
  /* --color-white: #fff; // ie --color-blue: #1f2ef5; */
  /* --color-lightgray: #ceceee; // ie --color-blue: #1f2ef5; */
  /* --color-gray: #5b5765; // ie --color-blue: #1f2ef5; */
  /* --color-purple: #6c28a7; // ie --color-blue: #1f2ef5; */
  /* --color-yellow: #fab908; // ie --color-blue: #1f2ef5; */
  /* --color-red: #ff6b6c; // ie --color-blue: #1f2ef5; */
  /* --color-green: #66f285; // ie --color-blue: #1f2ef5; */
}

html.hide-header-at-start.show-header #home-subscribeform {
  opacity: 1 !important;
}

html.page-home {
  --home-height-heroshape: 70px;
  --home-height-topheaderbottomcontainer: 60px;
}

.p-home__topheadercontainer {
  position: relative;
  min-height: 700px;
  background-color: var(--color-primary) !important;
  margin-bottom: var(--home-height-topheaderbottomcontainer);
}

html[data-is-tikkie=true] #home-tikkieheader {
  opacity: 1;
}
@media (max-width: 767.98px) {
  html[data-is-tikkie=true] #home-tikkie-header-bgimage-mobile {
    display: block !important;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: calc(100% - 140px);
    background-image: var(--background);
    background-size: cover;
    background-position: 50% 50%;
  }
  html[data-is-tikkie=true] #home-tikkie-header-bgimage-mobile::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

html.page-home .hero__shape {
  z-index: 6;
  height: var(--home-height-heroshape);
}
html.page-home .hero__shape--2 {
  bottom: -90px;
  z-index: 100;
}

@media (min-width: 768px) {
  html.page-home .hero__shape,
  .p-home__curvedtext,
  .p-home__topheadercontainerbg,
  .p-home__topheadercontainercurvedbg {
    display: block;
  }
}

.p-home__topheadercontainerbg,
.p-home__topheadercontainercurvedbg,
.p-home__topheaderbottombg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.p-home__topheadercontainerbg {
  z-index: 5;
  height: 700px;
}

.p-home__topheaderbottombg {
  background-color: #ff6b6c;
  top: 100%;
  height: var(--home-height-topheaderbottomcontainer);
}
.p-home__topheaderbottombg .splide__slide {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
}

.p-home__topheaderbottombgcontent {
  transform: translateY(-32px);
}

.p-home__topheaderbottombgcontentitem {
  color: #fff;
  margin-left: 8px;
  transform: translateY(2px);
}

.p-home__curvedtext {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% - 16px);
  z-index: 100;
  color: #fff;
}
.p-home__curvedtext > div {
  height: 2.58059em !important;
}

.p-home__curvedtext-checkimg {
  transform: translateY(-5px);
}

@media (max-width: 991.98px) {
  .p-home__curvedtext {
    overflow: hidden;
  }
}

@media (min-width: 992px) {
  .p-home__topheaderbottombgcontent {
    display: none;
  }
}
.p-home__hero {
  margin-bottom: 3rem;
}

.p-home__hero .hero {
  padding-bottom: 0;
  margin-top: 0;
  min-height: 0;
}

.p-home__usps {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 8px 24px;
  background-color: #fff;
  padding-top: 32px;
}
@media (min-width: 768px) {
  .p-home__usps {
    flex-direction: row;
    column-gap: 32px;
  }
}
.p-home__usps .p-home__usp-icon {
  margin-right: 8px;
}
.p-home__usps .p-home__usp-item {
  display: flex;
  align-items: center;
  color: var(--color-gray);
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 900;
  transform: translateY(-20px);
}

.p-home__usps {
  display: none !important;
}

.p-home__topheadercontainer--usps .p-home__curvedtext,
.p-home__topheadercontainer--nocurve .p-home__curvedtext {
  display: none !important;
}
.p-home__topheadercontainer--usps .p-home__curvedtext *,
.p-home__topheadercontainer--nocurve .p-home__curvedtext * {
  position: static !important;
  transform: none !important;
}

.p-home__topheadercontainer--nocurve:not(.p-home__topheadercontainer--usps) .p-home__topheaderbottombg {
  top: calc(100% - var(--home-height-topheaderbottomcontainer));
}
.p-home__topheadercontainer--nocurve:not(.p-home__topheadercontainer--usps) .hero__shape {
  display: none !important;
}
.p-home__topheadercontainer--nocurve:not(.p-home__topheadercontainer--usps) .p-home__topheaderbottombg {
  height: 85px;
}
.p-home__topheadercontainer--nocurve:not(.p-home__topheadercontainer--usps) .p-home__topheaderbottombgcontent {
  transform: none;
}
@media (min-width: 992px) {
  .p-home__topheadercontainer--nocurve:not(.p-home__topheadercontainer--usps) .p-home__curvedtext {
    display: block !important;
    position: absolute;
    width: 100%;
    transform: none;
    left: 0;
    height: 85px;
    top: calc(100% - var(--home-height-topheaderbottomcontainer));
  }
  .p-home__topheadercontainer--nocurve:not(.p-home__topheadercontainer--usps) .p-home__curvedtext > div {
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.p-home__topheadercontainer--usps .hero__shape {
  display: none !important;
}
.p-home__topheadercontainer--usps .hero__shape--usps {
  height: 4.375rem !important;
  bottom: 130px;
  display: block !important;
}
@media (min-width: 768px) {
  .p-home__topheadercontainer--usps .hero__shape--usps {
    bottom: 40px;
  }
}
.p-home__topheadercontainer--usps .p-home__usps {
  display: flex !important;
}
@media (min-width: 768px) {
  .p-home__topheadercontainer--usps .p-home__usps {
    max-width: none;
  }
}
.p-home__topheadercontainer--usps .p-home__usp-item {
  z-index: 100;
}
@media (min-width: 768px) {
  .p-home__topheadercontainer--usps .p-home__usp-item {
    transform: none;
  }
}
.p-home__topheadercontainer--usps .p-home__topheaderbottombg {
  display: none !important;
}
@media (min-width: 768px) {
  .p-home__topheadercontainer--usps .hero__shape--usps {
    display: block !important;
  }
}

html.page-home .p-home__ourmethod .hero:after,
body .p-home__ourmethod .hero:after {
  display: none !important;
}
html.page-home .p-home > .p-home__hero,
body .p-home > .p-home__hero {
  transform: translateY(0px);
}
html.page-home .p-home__hero > .hero > .hero__content,
body .p-home__hero > .hero > .hero__content {
  background: var(--color-primary) !important;
}
html.page-home #__layout > .section-primary,
body #__layout > .section-primary {
  background-color: #fff;
}
html.page-home .top-bar,
body .top-bar {
  background: transparent;
}
html.page-home .pagecontainer.homesubscribeform,
body .pagecontainer.homesubscribeform {
  padding: 32px 0 96px;
  overflow: unset;
  min-height: initial;
}
@media (min-width: 768px) {
  html.page-home .pagecontainer.homesubscribeform,
  body .pagecontainer.homesubscribeform {
    min-height: 550px;
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  html.page-home .p-home__hero > .hero > .hero__content,
  body .p-home__hero > .hero > .hero__content {
    padding-top: 0;
  }
}