:root {
  /* --color-blue: #1f2ef5; // ie --color-blue: #1f2ef5; */
  /* --color-coal: #0b1116; // ie --color-blue: #1f2ef5; */
  /* --color-pink: #f9e8d5; // ie --color-blue: #1f2ef5; */
  /* --color-black: #000; // ie --color-blue: #1f2ef5; */
  /* --color-white: #fff; // ie --color-blue: #1f2ef5; */
  /* --color-lightgray: #ceceee; // ie --color-blue: #1f2ef5; */
  /* --color-gray: #5b5765; // ie --color-blue: #1f2ef5; */
  /* --color-purple: #6c28a7; // ie --color-blue: #1f2ef5; */
  /* --color-yellow: #fab908; // ie --color-blue: #1f2ef5; */
  /* --color-red: #ff6b6c; // ie --color-blue: #1f2ef5; */
  /* --color-green: #66f285; // ie --color-blue: #1f2ef5; */
}

.w-twocols__container {
  display: grid;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: var(--color-gray);
  gap: 32px;
}
@media (min-width: 768px) {
  .w-twocols__container {
    font-size: 16px;
    line-height: 24px;
  }
}
.w-twocols__container .heading1,
.w-twocols__container .heading2,
.w-twocols__container .heading3,
.w-twocols__container .heading4 {
  color: #292233;
}
.w-twocols__container .heading4 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 800;
}
@media (min-width: 768px) {
  .w-twocols__container .heading4 {
    font-size: 32px;
    line-height: 32px;
  }
}
.w-twocols__container .wh-video {
  max-width: unset;
}
@media (min-width: 992px) {
  .w-twocols__container {
    grid-template-columns: 1fr 1fr;
    gap: 56px;
  }
}