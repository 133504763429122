/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
:root {
  /* --color-blue: #1f2ef5; // ie --color-blue: #1f2ef5; */
  /* --color-coal: #0b1116; // ie --color-blue: #1f2ef5; */
  /* --color-pink: #f9e8d5; // ie --color-blue: #1f2ef5; */
  /* --color-black: #000; // ie --color-blue: #1f2ef5; */
  /* --color-white: #fff; // ie --color-blue: #1f2ef5; */
  /* --color-lightgray: #ceceee; // ie --color-blue: #1f2ef5; */
  /* --color-gray: #5b5765; // ie --color-blue: #1f2ef5; */
  /* --color-purple: #6c28a7; // ie --color-blue: #1f2ef5; */
  /* --color-yellow: #fab908; // ie --color-blue: #1f2ef5; */
  /* --color-red: #ff6b6c; // ie --color-blue: #1f2ef5; */
  /* --color-green: #66f285; // ie --color-blue: #1f2ef5; */
}

.quote {
  --image-size: 123px;
  display: grid;
  grid-template-columns: var(--image-size) 1fr;
  align-items: center;
  gap: 35px;
}

.quote__balloon {
  background: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 44px;
  position: relative;
  padding: 18px 18px 18px 28px;
}

.quote__balloon-bubble {
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.quote__text,
.quote__name {
  font-size: 13px;
  line-height: 24px;
  color: var(--color-gray);
}

.quote__text {
  font-style: italic;
  margin-bottom: 1rem;
}

.quote__name {
  font-weight: 700;
  color: var(--color-gray);
}

.quote__imagecontainer {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.quote__imagecontainer img {
  border-radius: 100%;
  height: var(--image-size);
  aspect-ratio: 1/1;
  object-fit: cover;
}

@media (max-width: 767.98px) {
  .quote {
    --image-size: 150px;
    grid-template-columns: 3fr;
    gap: 16px;
  }
  .quote__balloon {
    padding: 12px 18px 14px 24px;
  }
  .quote__balloon-bubble {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .quote__imagecontainer {
    display: flex;
    justify-content: center;
    order: 2;
    margin-top: 16px;
  }
}