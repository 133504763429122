/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-footerbar {
  background: var(--color-purple);
  color: #fff;
  padding: 32px var(--page-padding-x);
}

.w-footerbar--red {
  background: var(--color-orange);
}

.w-footerbar__grid {
  display: grid;
  grid-template-columns: 1fr 250px;
  grid-gap: 16px;
  align-items: center;
}

.w-footerbar__title {
  font-weight: 800;
  color: #fff !important;
  margin-bottom: 0;
  line-height: 120%;
}

.w-footerbar__subtitle {
  line-height: 120%;
}

.w-footerbar__partner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

@media (max-width: 767.98px) {
  .w-footerbar__grid {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .w-footerbar__partnercontainer {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
}