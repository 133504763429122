.w-cta {
  margin-bottom: 40px;
}

.w-cta__content--socials {
  margin-top: 1rem;
}

.w-cta__container {
  position: relative;
}
.w-cta__container a,
.w-cta__container .licenseform__title {
  color: #fff;
}

.w-cta__bgimage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}