/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-work__grid {
  display: grid;
  grid-template-areas: "text" "quote" "properties";
  gap: 16px;
}

.w-work__text {
  grid-area: text;
}

.w-work__quote {
  grid-area: quote;
}

.w-work__properties {
  grid-area: properties;
}

.w-work .c-properties {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.w-work .c-properties__row {
  display: flex;
  align-items: center;
  gap: 16px;
}
.w-work .c-properties__title {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: var(--color-gray);
}

@media (min-width: 992px) {
  .w-work__grid {
    grid-template-columns: 1fr 260px;
    grid-template-areas: "text properties" "quote properties";
    gap: 32px 48px;
  }
}
@media (min-width: 1200px) {
  .w-work__grid {
    gap: 40px 104px;
  }
}