.w-twoblocks {
  margin-top: 80px;
  margin-bottom: 80px;
}

.w-twoblocks__container {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--grid-gutter);
  color: #fff;
}
@media (min-width: 1024px) {
  .w-twoblocks__container {
    grid-template-columns: 1fr 1fr;
  }
}

.w-twoblocks__blockleft,
.w-twoblocks__blockright {
  margin-bottom: 16px;
  min-height: 265px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.w-twoblocks__blockleft, .w-twoblocks__blockleft::after,
.w-twoblocks__blockright,
.w-twoblocks__blockright::after {
  border-radius: 24px;
}
.w-twoblocks__blockleft *,
.w-twoblocks__blockright * {
  z-index: 10;
}
.w-twoblocks__blockleft::after,
.w-twoblocks__blockright::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.35);
}
@media (min-width: 1024px) {
  .w-twoblocks__blockleft,
  .w-twoblocks__blockright {
    min-height: 400px;
    padding: 48px;
  }
}

.w-twoblocks__heading {
  font-size: 24px;
  line-height: 110%;
  font-weight: 900;
  margin-bottom: 16px;
}
@media (min-width: 1024px) {
  .w-twoblocks__heading {
    font-size: 40px;
    margin-bottom: 24px;
  }
}

.w-twoblocks__text {
  line-height: 19.12px;
  font-weight: 400;
  font-size: 14px;
}
@media (min-width: 1024px) {
  .w-twoblocks__text {
    line-height: 24.59px;
    font-size: 18px;
  }
}

.w-twoblocks__text--button {
  line-height: 21.86px;
  font-weight: 900;
  font-size: 16px;
  margin-bottom: 8px;
}

.w-twoblocks__link {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
}
@media (min-width: 768px) {
  .w-twoblocks__link {
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
  }
}

.w-twoblocks__hasbutton .w-twoblocks__link {
  font-size: 14px;
  line-height: 19.12px;
  opacity: 0.5;
}
@media (min-width: 768px) {
  .w-twoblocks__hasbutton .w-twoblocks__link {
    line-height: 25.2px;
  }
}

.w-twoblocks__bottomtext {
  color: var(--color-gray);
  letter-spacing: 0.18px;
  font-weight: 700;
}