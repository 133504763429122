.hero {
  position: relative;
  z-index: 1;
  padding-bottom: 9.375rem;
  color: #fff;
  background: #6c28a7;
}

@media (min-width: 768px) {
  .hero {
    min-height: 43.75rem;
  }
}
.hero--overflow {
  overflow: hidden;
}

.hero__image {
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate3d(-50%, -50%, 0);
}

.hero__image,
.hero__image img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.hero__image img {
  top: 0;
  left: 0;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.hero--image-covered .hero__image img {
  -o-object-fit: cover;
  object-fit: cover;
}

.hero__shape {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 4.375rem;
  color: #fff;
  user-select: none;
}
@media (max-width: 768px) {
  .hero__shape {
    height: 40px;
  }
}

.hero__content {
  position: relative;
  z-index: 2;
  padding-top: 3.125rem;
}

.hero__container {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  width: 100%;
  max-width: 81.25rem;
  height: 100%;
  transform: translate3d(-50%, 0, 0);
}

.hero__our-method-big {
  position: absolute;
  top: 0;
  right: -12.5rem;
  width: 37.5rem;
  pointer-events: none;
}

@media (min-width: 768px) {
  .hero__our-method-big {
    right: -18.75rem;
    width: 62.5rem;
  }
}
@media (min-width: 1400px) {
  .hero__our-method-big {
    right: 0;
  }
}
.hero__reviews-big {
  position: absolute;
  bottom: -20rem;
  left: -12.5rem;
  width: 37.5rem;
  pointer-events: none;
}

@media (min-width: 768px) {
  .hero__reviews-big {
    bottom: -28.125rem;
    width: 56.25rem;
  }
}
@media (min-width: 1400px) {
  .hero__reviews-big {
    right: 0;
  }
}
.hero__reviews-big img {
  transform: rotate(40deg);
}

.hero__about-us-big {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 37.5rem;
  pointer-events: none;
  transform: translate3d(-50%, 0, 0);
  overflow: hidden;
}

@media (min-width: 768px) {
  .hero__about-us-big {
    width: 50rem;
    margin-top: -7.5rem;
  }
}
@media (min-width: 992px) {
  .hero__about-us-big {
    width: 53.125rem;
    margin-left: -6.25rem;
  }
}
.hero__about-us-big img {
  transform: rotate(40deg);
}

.hero__fast-big {
  position: absolute;
  top: 3.125rem;
  left: -6.25rem;
  width: 37.5rem;
  pointer-events: none;
}

@media (min-width: 768px) {
  .hero__fast-big {
    top: -6.25rem;
    width: 50rem;
  }
}
.hero__fast-big img {
  transform: rotate(-10deg);
}

.hero__fast-small {
  position: absolute;
  top: -50px;
  right: -18.75rem;
  display: none;
  width: 37.5rem;
  pointer-events: none;
}

@media (min-width: 768px) {
  .hero__fast-small {
    display: block;
    width: 50rem;
  }
}
.hero__fast-small img {
  transform: rotate(10deg);
}