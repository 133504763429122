/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
:root {
  /* --color-blue: #1f2ef5; // ie --color-blue: #1f2ef5; */
  /* --color-coal: #0b1116; // ie --color-blue: #1f2ef5; */
  /* --color-pink: #f9e8d5; // ie --color-blue: #1f2ef5; */
  /* --color-black: #000; // ie --color-blue: #1f2ef5; */
  /* --color-white: #fff; // ie --color-blue: #1f2ef5; */
  /* --color-lightgray: #ceceee; // ie --color-blue: #1f2ef5; */
  /* --color-gray: #5b5765; // ie --color-blue: #1f2ef5; */
  /* --color-purple: #6c28a7; // ie --color-blue: #1f2ef5; */
  /* --color-yellow: #fab908; // ie --color-blue: #1f2ef5; */
  /* --color-red: #ff6b6c; // ie --color-blue: #1f2ef5; */
  /* --color-green: #66f285; // ie --color-blue: #1f2ef5; */
}

@media (min-width: 768px) {
  .widget--intro .hero {
    min-height: 30rem;
  }
}

.widget--intro .wh-video {
  position: relative;
  z-index: 12;
  margin-top: -150px;
  max-width: 826px;
}
@media (max-width: 767px) {
  .widget--intro .wh-video {
    max-width: 500px;
    max-height: initial;
  }
}
.widget--intro .wh-video__posterimage {
  width: 100%;
  height: 100%;
}
.widget--intro .w-intro__bgelements {
  position: absolute;
  top: -70px;
  left: 0;
  width: 100%;
  height: 700px;
  overflow-x: hidden;
  z-index: -1;
}
.widget--intro .w-intro__bgelement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}
.widget--intro .w-intro__bgelement--1 {
  margin-left: -520px;
  margin-top: 120px;
  transform: scaleY(-1);
  filter: blur(5px);
  width: 300px;
  height: auto;
}
.widget--intro .w-intro__bgelement--2 {
  margin-top: 100px;
  margin-left: 190px;
  transform: scaleX(-1) scaleY(-1);
  width: 500px;
  height: auto;
}
.widget--intro .w-button .btn::after {
  content: "";
  background: url("~@mod-looping_corporate/webdesigns/looping_corporate/web/img/intro-arrow.svg");
  width: 24px;
  height: 39px;
  position: absolute;
  left: 100%;
  top: 0;
  transform: translate(35px, -20px);
}

.w-intro__text > h1,
.w-intro__text > h2,
.w-intro__text > p.normal {
  color: #fff;
}
.w-intro__text i {
  color: var(--color-orange) !important;
}
.w-intro__text a {
  color: inherit;
}

@media (max-width: 767.98px) {
  .w-intro__text h1 {
    font-size: 40px;
    line-height: 1.2;
  }
}