@charset "UTF-8";
:root {
  /* --color-blue: #1f2ef5; // ie --color-blue: #1f2ef5; */
  /* --color-coal: #0b1116; // ie --color-blue: #1f2ef5; */
  /* --color-pink: #f9e8d5; // ie --color-blue: #1f2ef5; */
  /* --color-black: #000; // ie --color-blue: #1f2ef5; */
  /* --color-white: #fff; // ie --color-blue: #1f2ef5; */
  /* --color-lightgray: #ceceee; // ie --color-blue: #1f2ef5; */
  /* --color-gray: #5b5765; // ie --color-blue: #1f2ef5; */
  /* --color-purple: #6c28a7; // ie --color-blue: #1f2ef5; */
  /* --color-yellow: #fab908; // ie --color-blue: #1f2ef5; */
  /* --color-red: #ff6b6c; // ie --color-blue: #1f2ef5; */
  /* --color-green: #66f285; // ie --color-blue: #1f2ef5; */
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.page-subscribe-form #footer {
  display: none !important;
}
html.page-subscribe-form .p-page-section-inset-y {
  padding-bottom: 3rem !important;
}

html.page-subscribe input[type=checkbox] {
  width: 1.5rem;
  height: 1.5rem;
}
html.page-subscribe input[type=checkbox]:before {
  width: 1.5rem;
  height: 1.5rem;
}
html.page-subscribe div[data-wh-form-group-for=maintenance_booklet_remarks] .wh-form__label.label {
  color: var(--color-text-black);
  margin-left: 0;
}
html.page-subscribe .app-section-primary {
  overflow-x: hidden;
}
html.page-subscribe .header__subscribepageitems-contactbutton,
html.page-subscribe .header__menu-desktop {
  display: none;
}
@media (min-width: 768px) {
  html.page-subscribe .top-bar__phone {
    display: none !important;
  }
}
html.page-subscribe .c-pageloader.hide {
  display: none;
}
html.page-subscribe .tile {
  background: #fff;
  border-radius: 24px;
  padding: 16px;
}
html.page-subscribe .wh-form__fieldgroup--has-unit-select {
  display: flex;
  flex-direction: row;
}
html.page-subscribe .wh-form__fieldgroup--has-unit-select > .wh-form__fields {
  flex: 1;
}
html.page-subscribe .wh-form__fieldgroup--has-unit-select > .wh-form__fieldline {
  margin-top: 24px;
  width: 84px;
}
html.page-subscribe .wh-form__fieldgroup--has-unit-select .input__select-chevron {
  right: 20px;
}
html.page-subscribe .wh-form__fieldgroup--textarea,
html.page-subscribe .subscribe__star-rating,
html.page-subscribe .c-form__doublecol {
  grid-column: 1/-1;
}
html.page-subscribe .options-checkboxes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
}
html.page-subscribe .xsubscribe__form {
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem 2rem;
  color: var(--color-text-black);
}
@media (min-width: 768px) {
  html.page-subscribe .xsubscribe__form {
    gap: 40px;
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  html.page-subscribe .xsubscribe__form {
    gap: 60px;
  }
}
html.page-subscribe .xsubscribe__form .form-check {
  display: flex;
  gap: 8px;
  align-items: center;
}
html.page-subscribe .xsubscribe__form .form-check label {
  margin-bottom: 0;
}
html.page-subscribe .subscribe__star-rating .wh-form__fieldgroup {
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
}
html.page-subscribe .subscribe__star-rating .wh-form__fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
@media (min-width: 576px) {
  html.page-subscribe .subscribe__star-rating .wh-form__fields {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  html.page-subscribe .subscribe__star-rating .wh-form__fields {
    flex-direction: column;
  }
}
html.page-subscribe .subscribe__star-rating .wh-form__fieldgroup--pulldown {
  margin: 0 0 25px;
  border-bottom: 3px solid #dae4ed;
  padding: 0 0 5px 0;
}
html.page-subscribe .subscribe__star-rating select,
html.page-subscribe .subscribe__star-rating .wh-form__fieldline:after,
html.page-subscribe .subscribe__star-rating .input__select-chevron {
  display: none !important;
}
html.page-subscribe .subscribe__star-rating .wh-form__fieldgroup {
  display: flex;
  justify-content: space-between;
}
html.page-subscribe .subscribe__star-rating .label-container,
html.page-subscribe .subscribe__star-rating .label__container {
  background: none !important;
}
html.page-subscribe .subscribe__star-rating .subscribe__star-rating__label {
  margin-top: 0;
}
html.page-subscribe .subscribe__star-rating .label__container {
  position: static;
}
html.page-subscribe .subscribe__star-rating .label__container label {
  position: static;
  opacity: 1 !important;
  transform: none;
}
html.page-subscribe .subscribe__star-rating .input__select {
  width: auto;
  background: none;
}
html.page-subscribe .subscribe__star-rating__label {
  text-align: right;
  font-size: 16px;
  margin-top: 10px;
}
html.page-subscribe .subscribe__ctapayoff {
  margin-top: 5px;
  font-size: 90%;
  line-height: 120%;
  grid-column: 1/-1;
  text-align: center;
  font-style: italic;
}
html.page-subscribe .xsubscribe__formcontent,
html.page-subscribe .expectations {
  max-width: 455px;
}
@media (max-width: 768px) {
  html.page-subscribe .xsubscribe__formcontent,
  html.page-subscribe .expectations {
    padding: 1rem 0 0 0.5rem;
  }
}
@media (max-width: 767px) {
  html.page-subscribe .xsubscribe__formcontent .widget--pro_cons {
    display: none;
  }
}
html.page-subscribe .subscribe__objecttypeheading {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  grid-column: 1/-1;
  margin-bottom: 24px;
}
html.page-subscribe .subscribe__objecttypeheading-make-model {
  color: var(--color-text-black);
  margin-bottom: 0;
}
html.page-subscribe .subscribe__objecttypeheading-constructionyear {
  color: var(--color-gray);
  font-size: 16px;
}
html.page-subscribe h2 {
  font-size: 32px;
  color: var(--color-text-black);
}
html.page-subscribe p {
  font-size: 16px;
}
html.page-subscribe .c-form__hiddenfield {
  display: none;
}
html.page-subscribe .c-form__label {
  font-size: 16px;
  line-height: 24px;
}
html.page-subscribe .c-form__label {
  white-space: nowrap;
}
html.page-subscribe .c-form__fields {
  flex-grow: 1;
  max-width: 600px;
}
@media (min-width: 768px) {
  html.page-subscribe .c-form__fields--erroroutsidefields .wh-form__error {
    position: static;
  }
}
html.page-subscribe .input__select-container label,
html.page-subscribe .custom-select,
html.page-subscribe .text-input,
html.page-subscribe .c-form__navbutton--prev .label {
  color: var(--color-text-black);
  font-size: 16px;
}
html.page-subscribe .custom-select,
html.page-subscribe .text-input {
  height: 48px;
  padding: 0 1rem;
}
html.page-subscribe .custom-select {
  color: #000;
}
html.page-subscribe input[type=radio] {
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
}
@media (min-width: 768px) {
  html.page-subscribe .c-form__radio {
    display: grid;
    grid-template-columns: 170px 1fr;
    gap: 3rem;
  }
}
html.page-subscribe .c-textareacounter {
  text-align: right;
  transform: translateY(0px);
  font-size: 14px;
}
html.page-subscribe .c-subscribe-nav {
  margin-top: 3rem;
}
html.page-subscribe .c-subscribe-nav__progress--mobile,
html.page-subscribe .c-subscribe-nav__progress--desktop {
  transition: width 0.3s ease-in;
}
html.page-subscribe .c-subscribe-nav__mobilesteps::after,
html.page-subscribe .c-subscribe-nav__progress--mobile {
  position: absolute;
  height: 4px;
  border-radius: 8px;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
html.page-subscribe .c-subscribe-nav__mobilesteps::before {
  content: "🥳";
  position: absolute;
  height: 20px;
  width: 20px;
  right: 5%;
  z-index: 99;
  font-size: 21px;
  pointer-events: none;
  transform: translate(-1px, -4px);
}
@media (min-width: 768px) {
  html.page-subscribe .c-subscribe-nav__mobilesteps::before {
    right: 0;
  }
}
html.page-subscribe .c-subscribe-nav {
  display: flex;
  margin-bottom: 4rem;
}
html.page-subscribe .c-subscribe-nav *[data-step] {
  cursor: pointer;
}
html.page-subscribe .c-subscribe-nav__mobilesteps {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 0 5%;
}
html.page-subscribe .c-subscribe-nav__mobilesteps::after {
  content: "";
  width: 100%;
  background: #fff;
}
@media (min-width: 768px) {
  html.page-subscribe .c-subscribe-nav__mobilesteps {
    padding: 0 10%;
  }
}
html.page-subscribe .c-subscribe-nav__progress {
  position: absolute;
  width: 100%;
  background: #66f285;
  z-index: 5;
}
html.page-subscribe .c-subscribe-nav__checkmark {
  background: #66f285;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 25px;
  position: absolute;
}
html.page-subscribe .c-subscribe-nav__progress--desktop {
  display: none;
}
html.page-subscribe .c-subscribe-nav__title {
  color: #fff;
  font-weight: bold;
  opacity: 0.5;
  transition: opacity 0.3s, color 0.3s;
}
@media (hover: hover) {
  html.page-subscribe .c-subscribe-nav__title:hover {
    opacity: 0.75;
  }
}
html.page-subscribe .c-subscribe-nav__title.selected {
  opacity: 1;
  color: var(--color-orange);
}
html.page-subscribe .c-subscribe-nav__title--link {
  cursor: pointer;
}
@media (hover: hover) {
  html.page-subscribe .c-subscribe-nav__title--link:hover {
    color: var(--color-orange);
  }
}
html.page-subscribe .c-subscribe-nav__line,
html.page-subscribe .c-subscribe-nav__block {
  transition: background 0.3s;
  background: #fff;
}
.c-subscribe-nav--done html.page-subscribe .c-subscribe-nav__line, html.page-subscribe .c-subscribe-nav__line.selected,
.c-subscribe-nav--done html.page-subscribe .c-subscribe-nav__block,
html.page-subscribe .c-subscribe-nav__block.selected {
  background: var(--color-orange);
}
html.page-subscribe .c-subscribe-nav__line {
  height: 2px;
  position: relative;
  pointer-events: none;
}
html.page-subscribe .c-subscribe-nav__line,
html.page-subscribe .c-subscribe-nav__title {
  display: none;
}
html.page-subscribe .c-subscribe-nav__block {
  width: 14px;
  height: 14px;
  background: #fff;
  position: absolute;
  left: -1px;
  top: 50%;
  transform: translateY(calc(-50% - 0.5px));
  pointer-events: auto;
}
html.page-subscribe .c-subscribe-nav__line--block-end .c-subscribe-nav__block {
  left: auto;
  right: 0;
}
html.page-subscribe .c-subscribe-nav--done {
  display: none;
}
html.page-subscribe .c-subscribe-nav--done,
html.page-subscribe .c-subscribe-nav--done * {
  pointer-events: none !important;
}
html.page-subscribe .c-subscribe-nav--done .c-subscribe-nav__title {
  color: #fff !important;
}
html.page-subscribe .c-subscribe-nav__number {
  line-height: 100%;
  z-index: 10;
  position: relative;
  background: #fff;
  color: transparent;
  border-radius: 50%;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin: 0;
  border: 0;
  cursor: pointer;
  padding: 0;
  transition: background 0.3s, border 0.3s, color 0.3s;
  transition-delay: 0.22s;
}
html.page-subscribe .c-subscribe-nav__number:after {
  content: attr(data-title);
  position: absolute;
  bottom: -25px;
  line-height: 25px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}
html.page-subscribe .c-subscribe-nav__number--last {
  margin-right: 0;
}
html.page-subscribe .c-subscribe-nav__step-title-num {
  color: var(--color-red);
  text-transform: uppercase;
}
html.page-subscribe .c-subscribe-nav__step-title {
  font-size: 24px;
  line-height: 28px;
}
html.page-subscribe .c-form__field[data-field=damage_free] .c-form__label::after {
  content: "excl. gebruikerssporen";
  font-size: 12px;
  line-height: 100%;
  color: var(--color-text-black);
  opacity: 0.5;
  display: block;
}
html.page-subscribe .c-form__options {
  justify-content: flex-end;
}
html.page-subscribe .c-form__field[data-type=radio] {
  margin-bottom: 18px;
}
html.page-subscribe .c-form__field.wh-form__fieldgroup--checkbox .wh-form__fieldline {
  display: flex;
  align-items: center;
}
html.page-subscribe .thankyoupage h1 {
  font-size: 56px;
  line-height: 67.2px;
}
html.page-subscribe .thankyoupage :is(h1, h2, h3, p, li) {
  color: #fff;
}
html.page-subscribe .thankyoupage p.tagline {
  font-size: 24px;
  line-height: 40px;
}
html.page-subscribe .required-text {
  font-size: 12px;
  line-height: 21px;
  color: var(--color-text-black);
}
html.page-subscribe div[data-page="1"] .required-text {
  display: none;
}
@media (min-width: 768px) {
  html.page-subscribe div[data-page="4"] .required-text {
    position: absolute;
    right: 0;
    bottom: -47px;
  }
}
html.page-subscribe .expectations {
  padding-top: 0;
}
@media (min-width: 768px) {
  html.page-subscribe .expectations {
    padding-top: 1rem;
  }
}
html.page-subscribe .expectations > div {
  color: var(--color-gray);
}
html.page-subscribe .expectations .expectations__label {
  font-size: 14px;
  line-height: 25px;
  font-weight: 600;
  margin-bottom: 7px;
}
html.page-subscribe .expectations p {
  margin: 0;
}
html.page-subscribe .expectations p.text-gray {
  font-size: 1rem;
  line-height: 1.5rem;
}
html.page-subscribe .expectations h3 {
  font-size: 1.5rem;
  line-height: 29px;
  color: var(--color-text-black);
  margin-bottom: 2rem;
}
html.page-subscribe .expectations .expectations__list {
  list-style-type: none;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
html.page-subscribe .expectations .expectations__listitem {
  display: flex;
  align-items: center;
  gap: 1rem;
}
html.page-subscribe .expectations .expectations__no {
  padding-top: 2rem;
}
html.page-subscribe .button--primary {
  box-shadow: 0px 4px 0px 0px #452066;
  padding: 1rem 1.5rem;
  max-width: max-content;
}
html.page-subscribe .section-help {
  margin-top: 38px;
  display: none !important;
}
html.page-subscribe .section-help__buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}
@media (min-width: 768px) {
  html.page-subscribe .section-help__buttons {
    gap: 1.5rem;
    flex-direction: row;
  }
}
html.page-subscribe .wh-form__page--client .section-help {
  display: block;
}
@media (min-width: 768px) {
  html.page-subscribe .wh-form__page--client .section-help {
    display: none;
  }
}
html.page-subscribe .header__subscribepageitems {
  display: block;
}
html.page-subscribe .header__subscribepageitemscontainer {
  display: flex;
  align-items: center;
  gap: 0 50px;
}
@media (min-width: 992px) {
  html.page-subscribe .header__subscribepageitemscontainer {
    column-gap: 100px;
  }
}
html.page-subscribe .header__subscribepageitemscontainer .top-bar__reviews {
  grid-template-areas: "reviewsfbcompanylogo stars" "reviewsfbcompanylogo written";
  align-items: center;
  gap: 8px 0;
}
html.page-subscribe .header__subscribepageitemscontainer .top-bar__reviews .top-bar__reviewsfbcompanylogo {
  grid-area: reviewsfbcompanylogo;
}
html.page-subscribe .header__subscribepageitemscontainer .top-bar__reviews .top-bar__stars {
  grid-area: stars;
}
html.page-subscribe .header__subscribepageitemscontainer .top-bar__reviews .top-bar__written {
  grid-area: written;
}
html.page-subscribe .header__subscribepageitemscontainer .top-bar__reviewsfbcompanylogo {
  width: 123px;
}
html.page-subscribe .header__subscribepageitemscontainer .top-bar__written {
  margin-left: 0;
}
html.page-subscribe .header__subscribepageitemscontainer .top-bar__written,
html.page-subscribe .header__subscribepageitemscontainer .top-bar__written * {
  font-size: 17px;
  line-height: 100%;
  font-weight: 500 !important;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
}
@media (min-width: 576px) {
  html.page-subscribe .header__subscribepageitemscontainer .header__subscribepageitems-contactbutton,
  html.page-subscribe .header__subscribepageitemscontainer .top-bar__reviews {
    display: grid;
  }
}
html.page-subscribe .header__subscribepageitems-contactbutton .button {
  font-weight: 500;
  font-size: 16px;
  column-gap: 8px;
  box-shadow: none;
}
html.page-subscribe .header__subscribepageitems-contactbutton svg {
  transform: scale(1.35);
}
html.page-subscribe .ph--star,
html.page-subscribe .ph--star-fill {
  display: inline-block;
  width: 26px;
  height: 26px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  margin-right: 4px;
}
html.page-subscribe .ph--star[data-index="3"],
html.page-subscribe .ph--star-fill[data-index="3"] {
  margin-right: 0;
}
html.page-subscribe .ph--star {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cpath fill='%236c28a7' d='M239.2 97.29a16 16 0 0 0-13.81-11L166 81.17l-23.28-55.36a15.95 15.95 0 0 0-29.44 0L90.07 81.17l-59.46 5.15a16 16 0 0 0-9.11 28.06l45.11 39.42l-13.52 58.54a16 16 0 0 0 23.84 17.34l51-31l51.11 31a16 16 0 0 0 23.84-17.34l-13.51-58.6l45.1-39.36a16 16 0 0 0 4.73-17.09m-15.22 5l-45.1 39.36a16 16 0 0 0-5.08 15.71L187.35 216l-51.07-31a15.9 15.9 0 0 0-16.54 0l-51 31l13.46-58.6a16 16 0 0 0-5.08-15.71L32 102.35a.37.37 0 0 1 0-.09l59.44-5.14a16 16 0 0 0 13.35-9.75L128 32.08l23.2 55.29a16 16 0 0 0 13.35 9.75l59.45 5.14v.07Z'/%3E%3C/svg%3E");
}
html.page-subscribe .ph--star-fill {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cpath fill='%236c28a7' d='m234.5 114.38l-45.1 39.36l13.51 58.6a16 16 0 0 1-23.84 17.34l-51.11-31l-51 31a16 16 0 0 1-23.84-17.34l13.49-58.54l-45.11-39.42a16 16 0 0 1 9.11-28.06l59.46-5.15l23.21-55.36a15.95 15.95 0 0 1 29.44 0L166 81.17l59.44 5.15a16 16 0 0 1 9.11 28.06Z'/%3E%3C/svg%3E");
}
@media (min-width: 768px) {
  html.page-subscribe .c-form__radio {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  html.page-subscribe .c-form__options {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
  }
  html.page-subscribe .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  html.page-subscribe .c-form__fields {
    gap: 0.5rem;
    align-self: flex-start;
  }
  html.page-subscribe .c-form__field[data-field=tires] {
    margin-bottom: 1.5rem;
  }
  html.page-subscribe .c-form__field {
    margin-bottom: 0.5rem;
  }
  html.page-subscribe .c-form__field .form-check {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  html.page-subscribe .c-form__field .form-check label {
    margin: 0;
  }
  html.page-subscribe .tile--add-padding {
    padding: 40px;
  }
  html.page-subscribe .wh-form__button--submit {
    flex-grow: 1;
  }
}
@media (min-width: 992px) {
  html.page-subscribe .c-form__radio {
    flex-direction: row;
  }
  html.page-subscribe .subscribe__star-rating .wh-form__fields {
    flex-direction: row;
  }
}

html.page-subscribe.isformsentpage .c-subscribe-nav__mobilesteps {
  opacity: 0;
  pointer-events: none;
}

html.page-subscribe.isformsentpage .c-subscribe-nav__mobilesteps::before {
  transform: translate(-1px, -14px);
}

@media (max-width: 1199.98px) {
  .page-subscribe-sent .signup__image {
    display: none;
  }
}
.page-subscribe-sent .page-subscribe-sent__body {
  margin-top: 4rem;
  max-width: 800px;
}

html[lang=en] .c-form__field[data-field=damage_free] .c-form__label::after {
  content: "excl. traces of use";
}