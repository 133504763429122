html.page-contact .c-onlineiconcontainer {
  min-width: 25px;
}

.p-contact > .section-inset-y {
  padding-top: 5rem;
}
.p-contact .wh-form__richtext > * {
  color: var(--color-gray) !important;
}

.p-contact__container {
  gap: 4rem 0;
}

.contact__overlap {
  position: relative;
  margin-bottom: 3.125rem;
}

@media (min-width: 768px) {
  .contact__overlap {
    margin-top: -7.5rem;
    margin-bottom: 0;
  }
}
.contact__link {
  color: inherit;
  text-decoration: none;
}

.contact__link:hover {
  color: inherit;
  text-decoration: underline;
}