.w-inline-loopinglogo svg {
  display: inline;
  max-width: unset;
  width: auto;
  height: 24px;
  margin: 0 8px;
  fill: var(--color-purple);
}
.heading1 .w-inline-loopinglogo svg {
  height: 80px;
}
.heading2 .w-inline-loopinglogo svg {
  height: 40px;
}