:root {
  /* --color-blue: #1f2ef5; // ie --color-blue: #1f2ef5; */
  /* --color-coal: #0b1116; // ie --color-blue: #1f2ef5; */
  /* --color-pink: #f9e8d5; // ie --color-blue: #1f2ef5; */
  /* --color-black: #000; // ie --color-blue: #1f2ef5; */
  /* --color-white: #fff; // ie --color-blue: #1f2ef5; */
  /* --color-lightgray: #ceceee; // ie --color-blue: #1f2ef5; */
  /* --color-gray: #5b5765; // ie --color-blue: #1f2ef5; */
  /* --color-purple: #6c28a7; // ie --color-blue: #1f2ef5; */
  /* --color-yellow: #fab908; // ie --color-blue: #1f2ef5; */
  /* --color-red: #ff6b6c; // ie --color-blue: #1f2ef5; */
  /* --color-green: #66f285; // ie --color-blue: #1f2ef5; */
}

.widget--news {
  margin-top: 80px;
}

.w-news {
  --w-news-itempadding: 12px;
}
.w-news > div > a {
  color: #292233;
  font-size: 14px;
  margin-top: 24px;
  overflow-x: hidden;
}
@media (min-width: 768px) {
  .w-news > div > a {
    margin-top: 16px;
  }
}
.w-news h4 {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 800;
  line-height: 24px;
}
@media (min-width: 768px) {
  .w-news h4 {
    margin-bottom: 40px;
    font-size: 32px;
    font-weight: 800;
    line-height: 32px;
  }
}
@media (min-width: 768px) {
  .w-news {
    --w-news-itempadding: 24px;
  }
}

.w-news__newscontainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  overflow-x: auto;
}

.w-news__item,
.w-news__item-inner {
  padding: var(--w-news-itempadding);
  border-radius: 8px;
}

.w-news__item {
  position: relative;
  aspect-ratio: 392/320;
  height: 200px;
  color: #fff;
  transition: background-size 0.3s;
  background-size: 100% !important;
  display: block;
}
.w-news__item button {
  position: absolute;
  padding: 10px 20px;
}
@media (hover: hover) {
  .w-news__item:hover {
    color: #fff;
    text-decoration: none;
    background-size: 120% !important;
  }
  .w-news__item:hover .w-news__image {
    transform: scale(1.1);
  }
}
@media (min-width: 900px) {
  .w-news__item {
    width: 100%;
    height: auto;
  }
}

.w-news__item-inner {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.w-news__content {
  position: absolute;
  bottom: var(--w-news-itempadding);
  z-index: 11;
  padding-right: 20px;
}

.w-news__title {
  font-weight: 500;
  word-break: keep-all;
  position: relative;
  font-size: 16px;
  line-height: 24px;
}

.w-news__date {
  font-size: 12px;
}

.w-news__overlay,
.w-news__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.w-news__overlay {
  background: linear-gradient(180deg, rgba(33, 27, 41, 0) 26%, #211b29 89.75%);
  border-radius: 8px;
  z-index: 10;
}

.w-news__image {
  max-width: initial;
  width: 100%;
  height: 100%;
  display: block;
  transition: transform 0.3s;
}