:root {
  /* --color-blue: #1f2ef5; // ie --color-blue: #1f2ef5; */
  /* --color-coal: #0b1116; // ie --color-blue: #1f2ef5; */
  /* --color-pink: #f9e8d5; // ie --color-blue: #1f2ef5; */
  /* --color-black: #000; // ie --color-blue: #1f2ef5; */
  /* --color-white: #fff; // ie --color-blue: #1f2ef5; */
  /* --color-lightgray: #ceceee; // ie --color-blue: #1f2ef5; */
  /* --color-gray: #5b5765; // ie --color-blue: #1f2ef5; */
  /* --color-purple: #6c28a7; // ie --color-blue: #1f2ef5; */
  /* --color-yellow: #fab908; // ie --color-blue: #1f2ef5; */
  /* --color-red: #ff6b6c; // ie --color-blue: #1f2ef5; */
  /* --color-green: #66f285; // ie --color-blue: #1f2ef5; */
}

.footer {
  padding-top: 4rem;
  padding-bottom: 2rem;
}
.footer .section-inset-y {
  padding-bottom: 2rem;
}
.footer .text-primary {
  color: var(--color-primary);
}
.footer .w-footerbar {
  margin-bottom: 56px;
}

.footer--hasbar {
  padding-top: 0;
}

.footer__cta {
  max-width: 808px;
  margin: 0 auto;
  background: var(--color-purple);
  border-radius: 24px;
  padding: 32px 48px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 30px);
  text-align: center;
}
@media (min-width: 808px) {
  .footer__cta {
    padding: 48px 128px;
  }
}
.footer__cta > *,
.footer__cta a {
  color: #fff !important;
}
.footer__cta h2 i,
.footer__cta h3 i {
  color: var(--color-orange);
}

.footer__row {
  display: grid;
  align-items: flex-start;
  gap: 2rem 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "contact contact" "links1 links2" "social social";
}
.footer__row .footer__col--contact {
  grid-area: contact;
}
.footer__row .footer__col--links1 {
  grid-area: links1;
}
.footer__row .footer__col--links2 {
  grid-area: links2;
}
.footer__row .footer__col--social {
  grid-area: social;
}
.footer__row .footer__col--extralinks {
  grid-area: extralinks;
}
.footer__row.footer__row--hasextralinks {
  grid-template-areas: "contact contact" "links1 links2" "social social" "extralinks extralinks";
}

.footer__button--sell {
  margin-top: 1rem;
}

.footer {
  z-index: 10;
  position: relative;
  background: #fff;
  line-height: 36px;
}

.footer__socialcontent {
  display: flex;
  gap: 1rem;
}
.footer__socialcontent .whatsapp__button-go {
  margin-top: 0;
}

.footer__logoimg {
  width: 146px;
}

.footer__logo,
.footer__shape {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.footer__shape {
  position: absolute;
  top: -4.375rem;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 4.5rem;
}

.footer li {
  margin-bottom: 10px;
}

.footer a {
  color: var(--color-gray);
  text-decoration: none;
}

.footer__social-icons {
  align-items: center;
  display: inline-flex;
}

.footer__social-icons div {
  margin-right: 0.625rem;
}

@media (hover: hover) {
  .footer__extralinks a:hover,
  .footer__menu a:hover,
  .footer__col--contact a:hover {
    color: var(--color-primary);
  }
}

.footer__extralinks {
  display: flex;
  flex-direction: column;
  gap: 0 1rem;
  padding-left: 0;
  margin-bottom: 0 !important;
  list-style: none;
}
@media (min-width: 576px) {
  .footer__extralinks {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.footer__menu a {
  line-height: 1.5;
}

@media (min-width: 576px) {
  .footer__socialcontent {
    flex-flow: row wrap;
  }
}
@media (min-width: 768px) {
  .footer__menu {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (min-width: 992px) {
  .footer__row {
    gap: 1rem 2rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "contact links1 links2 social";
  }
  .footer__row.footer__row--hasextralinks {
    row-gap: 3rem;
    grid-template-areas: "contact links1 links2 social" "extralinks extralinks extralinks extralinks";
  }
  .footer__row .footer__extralinks {
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 0;
  }
  .footer__col--links1,
  .footer__col--links2,
  .footer__col--social {
    transform: translateY(-50px);
  }
}