/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.widget--reviewcompare {
  background: rgba(129, 69, 183, 0.1);
}

.w-reviewcompare {
  padding-bottom: 42px;
  padding-top: 42px;
}

.w-reviewcompare__gridarea--looping {
  grid-area: looping;
}

.w-reviewcompare__gridarea--reviews {
  grid-area: reviews;
}

.w-reviewcompare__gridarea--dealer {
  grid-area: dealer;
}

.w-reviewcompare__logo {
  width: auto;
  height: 39px;
}

.w-reviewcompare__highlight {
  justify-content: flex-start !important;
}

.w-reviewcompare__grid {
  display: flex;
  flex-direction: column;
  gap: 24px 0;
}

.w-reviewcompare__itemtitle {
  margin-bottom: 17px;
}

@media (min-width: 768px) {
  .w-reviewcompare__grid {
    display: grid;
    grid-template-areas: "looping dealer" "reviews reviews";
    grid-template-columns: 1fr 1fr;
  }
  .w-reviewcompare__intro {
    text-align: center;
  }
  .w-reviewcompare__logo {
    height: 32px;
  }
  .w-reviewcompare__itemtitle--title {
    margin-bottom: 10px;
  }
}
@media (min-width: 992px) {
  .w-reviewcompare__grid {
    grid-template-areas: "looping reviews dealer";
    grid-template-columns: 1fr 300px 1fr;
    gap: 0 24px;
    max-width: 1020px;
    margin: 0 auto;
  }
  .w-reviewcompare__grid .w-reviewcompare__highlight {
    border-top: 0.2px solid rgba(51, 51, 51, 0.3);
    padding-top: 12px;
    background-position-y: 12px;
    width: 100%;
    padding-left: 40px;
  }
  .w-reviewcompare__grid .w-reviewcompare__highlight:first-child {
    border-top: 0;
  }
  .w-reviewcompare__grid .w-reviewcompare__gridarea--reviews {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.w-reviewcompare .c-reviews {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}
.w-reviewcompare .c-reviews__stars {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.w-reviewcompare .c-reviews__outercircle {
  width: 233px;
  height: 233px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: rgba(255, 255, 255, 0.67);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.w-reviewcompare .c-reviews__innercircle {
  width: 203px;
  height: 203px;
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.w-reviewcompare .c-reviews__text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.w-reviewcompare .c-reviews__num {
  font-size: 14px;
  line-height: 100%;
}
.w-reviewcompare .c-reviews__score {
  font-size: 37px;
  line-height: 100%;
  font-weight: 700;
}

.w-reviewcompare__highlights {
  margin-bottom: 0;
}

.w-reviewcompare__highlight:last-child {
  margin-bottom: 0;
}

.w-reviewcompare__intro {
  margin-bottom: 32px;
}