/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.licenseform {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.licenseform .button,
.licenseform__button-go,
.homesubscribeform__button-go {
  height: 55px;
}

.licenseform__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 8px;
  color: #000;
}

.licenseform__errorcontainer {
  color: #fff;
  margin-top: 0;
  font-size: 90%;
  display: none;
}
.licenseform__errorcontainer.show {
  display: block;
}

.licenseform__form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  flex-flow: row wrap;
}

.homesubscribeform__button-go[data-loading=true],
.licenseform__button-go[data-loading=true] {
  color: transparent;
  pointer-events: none;
}
@media (hover: hover) {
  .homesubscribeform__button-go[data-loading=true]:hover,
  .licenseform__button-go[data-loading=true]:hover {
    background: var(--color-yellow);
  }
}
.homesubscribeform__button-go[data-loading=true] .lds-ripple,
.licenseform__button-go[data-loading=true] .lds-ripple {
  opacity: 1;
}

.homesubscribeform__license-wrapper,
.licenseform__license-input-wrapper,
.licenseform__license-wrapper {
  width: 100%;
  max-width: initial;
}

.licenseform__button-go {
  min-width: 126px;
  width: 100%;
}

.homesubscribeform .homesubscribeform__button-go .lds-ripple,
.licenseform__button-go .lds-ripple {
  transform: scale(1) translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 3px;
  margin-left: 5px;
  opacity: 0;
  transition: opacity 0.3s;
}

.homesubscribeform__nolicense,
.licenseform__nolicense {
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 25.2px;
  color: red;
  color: #1a2e33;
  opacity: 0.5;
}

@media (min-width: 576px) {
  .licenseform__form {
    width: 100%;
    flex-direction: row;
    flex-flow: initial;
  }
  .licenseform__license-input-wrapper,
  .licenseform__license-wrapper,
  .licenseform__button-go {
    width: auto !important;
  }
}