/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.widget--pricescompare {
  background: rgba(129, 69, 183, 0.1);
}

@media (max-width: 991.98px) {
  .w-pricescompare__outercontainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .w-pricescompare__outercontainer .w-pricescompare__intro {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.w-pricescompare {
  transform: translateY(0px);
  padding-bottom: 80px;
  padding-top: 42px;
}
.w-pricescompare .hero {
  background: #fff;
  color: #292233;
  min-height: auto;
}

.w-pricescompare .splide__slide {
  width: 80vw;
  max-width: 300px;
}
.w-pricescompare .c-block {
  border-radius: 18px;
  padding: 24px 0;
  background: linear-gradient(0deg, #6C28A7 -28.62%, rgba(108, 40, 167, 0) 81.76%);
}
.w-pricescompare .c-block__content {
  padding: 0 20px;
}
.w-pricescompare .c-block__price {
  font-size: 25px;
  line-height: 100%;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
}
.w-pricescompare .c-block__stars {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-bottom: 8px;
}
.w-pricescompare .c-block__highlights {
  margin-top: 16px;
}
.w-pricescompare .c-block__highlight {
  font-size: 15px;
  line-height: 20px;
}
.w-pricescompare .c-block__imagecontainer {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
.w-pricescompare .c-block__imagecontainer img {
  display: inline-block;
  height: auto;
  max-width: 80%;
}
.w-pricescompare .c-block--islooping {
  background: #fff;
}
.w-pricescompare .c-block--islooping .c-block__price {
  font-size: 37px;
  line-height: 100%;
}
.w-pricescompare .c-block--islooping .c-block__imagecontainer img {
  max-width: 100%;
}

.w-pricescompare__itemtitle {
  font-size: var(--h5-font-size);
  line-height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-weight: 700;
}

.w-pricescompare__intro {
  margin-bottom: 32px;
}

@media (min-width: 992px) {
  .w-pricescompare .splide__slide {
    width: 330px;
  }
  .w-pricescompare .c-block__content {
    padding-left: 32px;
    padding-right: 32px;
  }
  .w-pricescompare__intro {
    text-align: center;
  }
  .w-pricescompare__blocks {
    display: flex !important;
    column-gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    overflow-x: auto;
    justify-content: center;
  }
}