:root {
  /* --color-blue: #1f2ef5; // ie --color-blue: #1f2ef5; */
  /* --color-coal: #0b1116; // ie --color-blue: #1f2ef5; */
  /* --color-pink: #f9e8d5; // ie --color-blue: #1f2ef5; */
  /* --color-black: #000; // ie --color-blue: #1f2ef5; */
  /* --color-white: #fff; // ie --color-blue: #1f2ef5; */
  /* --color-lightgray: #ceceee; // ie --color-blue: #1f2ef5; */
  /* --color-gray: #5b5765; // ie --color-blue: #1f2ef5; */
  /* --color-purple: #6c28a7; // ie --color-blue: #1f2ef5; */
  /* --color-yellow: #fab908; // ie --color-blue: #1f2ef5; */
  /* --color-red: #ff6b6c; // ie --color-blue: #1f2ef5; */
  /* --color-green: #66f285; // ie --color-blue: #1f2ef5; */
}

.track-trace {
  position: relative;
}
.track-trace .tile {
  padding: 16px;
  padding: 1.875rem;
  margin-bottom: 3.75rem;
  background: hsla(0, 0%, 100%, 0.1);
  border-radius: 0.5rem;
  -webkit-backdrop-filter: blur(56px);
  backdrop-filter: blur(56px);
  border: 1px solid hsla(0, 0%, 100%, 0.2);
}
.track-trace .steps {
  margin-bottom: 3rem;
  position: relative;
}
.track-trace .step {
  margin-bottom: 1.5rem;
}
.track-trace .step:not(.step--is-active) {
  opacity: 0.25;
}
.track-trace .step__number {
  margin-bottom: 0.5rem;
}
.track-trace .h6 {
  line-height: 120%;
}
.track-trace .c-form__options-options {
  display: block !important;
}
.track-trace .c-form__options-options .field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.track-trace .c-form__options-options .field input[type=checkbox] {
  transform: translateY(2.5px);
}
.track-trace .track-trace__text a {
  color: var(--color-orange);
}
.track-trace .track-trace__steptext > h1,
.track-trace .track-trace__steptext > h2,
.track-trace .track-trace__steptext > h3,
.track-trace .track-trace__steptext > h4,
.track-trace .track-trace__steptext > h5,
.track-trace .track-trace__steptext > h6,
.track-trace .track-trace__steptext > p {
  color: #fff !important;
}
.track-trace .track-trace__steptext .checklist {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
@media (min-width: 768px) {
  .track-trace .track-trace__top {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
  }
  .track-trace .track-trace__title {
    grid-column: span 8/span 8;
  }
  .track-trace .track-trace__vehicle-details {
    grid-column: span 4/span 4;
  }
  .track-trace .tile--add-padding {
    padding: 2.5rem 5rem;
  }
  .track-trace .steps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-bottom: 1px solid #292233;
    padding-bottom: 1rem;
  }
  .track-trace .steps:before {
    height: 0.25rem;
    background-color: #fab908;
    content: "";
    position: absolute;
    border-radius: 0.125rem;
    left: 0;
    width: 100%;
    bottom: 1rem;
    z-index: 1;
    max-width: var(--steps-progress);
    transition: max-width 0.2s ease-out;
  }
  .track-trace .step {
    margin-bottom: 1rem;
  }
  .track-trace .step__number {
    margin-bottom: 1rem;
  }
}