/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-ourmethod {
  transform: translateY(0px);
  padding-bottom: 80px;
}
.w-ourmethod .hero {
  background: #fff;
  color: #292233;
  min-height: auto;
}

.w-ourmethod__container {
  display: grid;
  gap: 1.5rem 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr;
  margin-bottom: 0;
  margin-top: 32px;
}

.w-ourmethod__heading h2 {
  color: var(--color-purple);
  margin-bottom: 8px;
}
.w-ourmethod__heading h2 i {
  color: var(--color-orange);
}
.w-ourmethod__heading h3 {
  color: var(--color-orange);
  font-size: 24px;
  line-height: 120%;
}

.step__imagecontainer {
  width: 100%;
  aspect-ratio: 1/1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 16px 24px;
  gap: 4px;
  margin-bottom: 24px;
  max-width: 100%;
}
.step__imagecontainer .step__bgimage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}
.step__imagecontainer:after {
  content: "";
  border-radius: 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 160px;
  transform: rotate(180deg);
  z-index: 0;
  background: linear-gradient(180deg, rgba(108, 40, 167, 0.9) 0%, rgba(108, 40, 167, 0.820312) 37.5%, rgba(108, 40, 167, 0) 100%);
}

.step__number,
.step__title {
  position: relative;
  z-index: 5;
}

.step__number {
  color: var(--color-orange);
  margin-bottom: 0;
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
}

.step {
  position: relative;
  opacity: 0.6;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

.step__title {
  font-size: 22px;
  line-height: 26px;
  font-weight: 900;
  margin-bottom: 0;
  color: #fff;
}

.step__description {
  font-weight: 400;
}
@media (min-width: 768px) {
  .step__description {
    font-size: 16px;
    line-height: 24px;
  }
}

.step--label-uppercase .step__number {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.25rem;
}

.step--is-active {
  opacity: 1;
}

@media (min-width: 450px) {
  .w-ourmethod__container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 600px) {
  .w-ourmethod__container {
    gap: 1.5rem;
  }
}
@media (min-width: 900px) {
  .w-ourmethod__container {
    grid-template-columns: repeat(4, 1fr);
  }
}
.w-ourmethod--nobackgroundimages .step__imagecontainer {
  aspect-ratio: unset;
  background: none !important;
  margin-bottom: 0;
  padding: 0;
  gap: 0;
}
.w-ourmethod--nobackgroundimages .step__bgimage,
.w-ourmethod--nobackgroundimages .step__imagecontainer::after {
  display: none;
}
.w-ourmethod--nobackgroundimages .step__title {
  border-bottom: 1px solid #292233;
  color: var(--color-primary) !important;
  margin-bottom: 24px;
  margin-top: 0.625rem;
  padding-bottom: 24px;
}
.w-ourmethod--nobackgroundimages .step__number {
  font-size: 16px;
}