@media (min-width: 768px) {
  .widget--photos .hero {
    min-height: 30rem;
  }
}

.w-photos__text > h1,
.w-photos__text > h2,
.w-photos__text > p.normal {
  color: #fff;
}
.w-photos__text i {
  color: var(--color-orange) !important;
}
.w-photos__text a {
  color: inherit;
}

.w-photos--simple {
  padding-bottom: 8rem;
}