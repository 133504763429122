/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-sendphotos {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.w-sendphotos .whatsapp__button-go {
  margin-top: 0;
}
.w-sendphotos .button {
  height: 60px;
  width: 100%;
}
@media (min-width: 768px) {
  .w-sendphotos .button {
    width: 350px;
  }
}

.w-sendphotos__buttons {
  display: flex;
  gap: 2rem;
  flex-flow: row wrap;
  margin-top: 32px;
}
.w-sendphotos__buttons .button {
  width: 350px;
  max-width: 100%;
}

@media (min-width: 1120px) {
  .w-sendphotos {
    flex-direction: row;
    column-gap: 1rem;
  }
  .w-sendphotos .button {
    margin-right: 0;
  }
}