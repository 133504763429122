/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.page-vacancies-index .contentpage-wh-rtd > .container-xl {
  max-width: unset;
  padding: unset;
}

@media (min-width: 768px) {
  html.page-vacancy-details .homesubscribeform__image {
    max-width: 420px;
    transform: unset;
    top: 0;
    display: block;
    right: 0;
    bottom: 0;
    left: auto;
    transform: translateY(50px);
  }
}

.p-vacancydetails .homesubscribeform__content {
  max-width: 800px;
}
.p-vacancydetails .wh-form .label {
  color: #fff;
}
.p-vacancydetails .c-properties {
  padding-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  max-width: 500px;
}
.p-vacancydetails .c-properties__row {
  display: flex;
  align-items: center;
  gap: 16px;
}
.p-vacancydetails .c-properties__title {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #fff;
}
@media (max-width: 767.98px) {
  .p-vacancydetails .pagecontainer.homesubscribeform {
    padding-bottom: 40px;
  }
}

.p-vacancydetails__headercontactbutton {
  margin-top: 48px;
  margin-bottom: 1rem;
}