/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.widget--textimage {
  padding-top: 72px;
  padding-bottom: 72px;
  background: linear-gradient(180deg, rgba(132, 64, 191, 0) 0%, rgba(132, 64, 191, 0.15) 100%);
}

.w-textimage__container {
  display: grid;
  font-size: 16px;
  color: var(--color-gray);
  gap: 2rem 0;
}
.w-textimage__container .heading1,
.w-textimage__container .heading2,
.w-textimage__container .heading3,
.w-textimage__container .heading4 {
  color: #292233;
}
.w-textimage__container .heading4 {
  font-size: 32px;
}
@media (min-width: 768px) {
  .w-textimage__container {
    grid-template-columns: 1fr 1fr;
    column-gap: var(--grid-gutter);
  }
}

.w-textimage__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.w-textimage__text > *:last-child {
  margin-bottom: 0;
}

.w-textimage__imagecontainer {
  display: flex;
  justify-self: end;
  align-items: center;
  width: 100%;
}
@media (min-width: 768px) {
  .w-textimage__imagecontainer {
    max-width: 496px;
  }
}

.w-textimage__image {
  border-radius: 8px;
  width: 100%;
}

.w-textimage--imageleft .w-textimage__text {
  order: 2;
}

.w-textimage--imageleft .w-textimage__imagecontainer {
  justify-self: start;
}

.w-textimage__imagecontainer {
  position: relative;
}

.w-textimage__marker {
  position: absolute;
}

.w-textimage--design2023 .heading1,
.w-textimage--design2023 .heading2,
.w-textimage--design2023 .heading3,
.w-textimage--design2023 .heading4 {
  color: var(--color-purple);
}
.w-textimage--design2023 .heading1 i,
.w-textimage--design2023 .heading2 i,
.w-textimage--design2023 .heading3 i,
.w-textimage--design2023 .heading4 i {
  color: var(--color-orange);
}
.w-textimage--design2023 .w-textimage__text {
  justify-content: flex-start;
}
.w-textimage--design2023 .w-textimage__imagecontainer {
  align-items: flex-start;
}

.w-textimage__licenseform {
  margin-top: 24px;
}

.w-textimage__imagecontainer {
  position: relative;
}
.w-textimage__imagecontainer .quote {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
}
.w-textimage__imagecontainer .quote__balloon {
  width: 180px;
  margin-top: -32px;
  z-index: 10;
}
.w-textimage__imagecontainer .quote__imagecontainer {
  position: absolute;
  top: 6px;
  right: -58px;
  z-index: 10;
  margin-top: 0;
  transform: translateX(50%);
}
.w-textimage__imagecontainer .quote__image {
  width: 62px;
  aspect-ratio: 1;
  height: auto;
}
.w-textimage__imagecontainer .quote__balloon-bubble {
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .w-textimage__imagecontainer .quote__image {
    width: 92px;
  }
  .w-textimage__imagecontainer .quote__balloon {
    margin-top: -44px;
    margin-left: -30px;
  }
  .w-textimage__imagecontainer .quote__imagecontainer {
    top: -14px;
    right: -65px;
  }
}
@media (min-width: 1200px) {
  .w-textimage__imagecontainer .quote {
    transform: translate(-100px, 100px);
  }
  .w-textimage__imagecontainer .quote__imagecontainer {
    top: -56px;
    right: -101px;
  }
  .w-textimage__imagecontainer .quote__balloon {
    width: 200px;
    margin-top: -74px;
    margin-left: -16px;
  }
}

html[data-country=be] .w-textimage__imagecontainer .quote {
  transform: translateY(-20px);
}
html[data-country=be] .w-textimage__imagecontainer .quote + .w-textimage__imagecontainer {
  padding-top: 70px;
}
@media (min-width: 768px) {
  html[data-country=be] .w-textimage__imagecontainer .quote {
    transform: translateY(5px);
  }
  html[data-country=be] .w-textimage__imagecontainer .quote + .w-textimage__imagecontainer {
    transform: translateY(20px);
  }
}
@media (min-width: 1200px) {
  html[data-country=be] .w-textimage__imagecontainer .quote {
    transform: translate(-80px, 60px);
  }
}