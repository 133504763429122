:root {
  /* --color-blue: #1f2ef5; // ie --color-blue: #1f2ef5; */
  /* --color-coal: #0b1116; // ie --color-blue: #1f2ef5; */
  /* --color-pink: #f9e8d5; // ie --color-blue: #1f2ef5; */
  /* --color-black: #000; // ie --color-blue: #1f2ef5; */
  /* --color-white: #fff; // ie --color-blue: #1f2ef5; */
  /* --color-lightgray: #ceceee; // ie --color-blue: #1f2ef5; */
  /* --color-gray: #5b5765; // ie --color-blue: #1f2ef5; */
  /* --color-purple: #6c28a7; // ie --color-blue: #1f2ef5; */
  /* --color-yellow: #fab908; // ie --color-blue: #1f2ef5; */
  /* --color-red: #ff6b6c; // ie --color-blue: #1f2ef5; */
  /* --color-green: #66f285; // ie --color-blue: #1f2ef5; */
}

c-menu {
  --menu-bg-color: var(--color-purple);
  --menu-header-height: 100px;
  position: relative;
  z-index: 99999;
}
c-menu .c-menu__menu {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  background-color: var(--menu-bg-color);
  background-size: cover;
  display: grid;
  grid-template-areas: "header" "links" "footer";
  grid-template-rows: var(--menu-header-height) 1fr 100px;
  grid-template-columns: 1fr;
}
c-menu .c-menu__menu .c-menu__links {
  grid-area: links;
}
c-menu .c-menu__menu .c-menu__social {
  grid-area: social;
}
c-menu .c-menu__menu .c-menu__phonecontainer {
  grid-area: phonecontainer;
}
c-menu .c-menu__menu .c-menu__mailcontainer {
  grid-area: mailcontainer;
}
c-menu .c-menu__menu .c-menu__header {
  grid-area: header;
}
c-menu .c-menu__menu .navbar-nav > li:last-child {
  border-bottom: 1px solid #fff;
}
c-menu .c-menu__menu .navbar-nav > li > a {
  width: 100%;
  justify-content: space-between;
  font-style: normal;
  border-top: 1px solid #fff;
  font-family: var(--font-family);
  line-height: 1.65;
  letter-spacing: 0.01em;
  list-style: none;
  text-transform: lowercase;
  font-weight: 900;
  font-size: 20px;
  color: #fff;
  text-align: center;
  display: block;
  padding: 1rem 0;
  transition: background 0.3s, color 0.3s;
  text-decoration: none;
}
c-menu .c-menu__menu .navbar-nav > li > a:hover {
  text-decoration: none;
}
c-menu .c-menu__menu .navbar-nav > li > a.selected {
  text-decoration: underline;
}
c-menu .c-menu__menu .navbar-nav > li > ul {
  background-color: transparent;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2rem;
}
c-menu .c-menu__menu .navbar-nav > li > ul.show {
  display: flex;
}
c-menu .c-menu__menu .navbar-nav > li > ul > li > a {
  color: #3c3c3b;
  padding: 0 0 0 24px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
}
c-menu .c-menu__menu .navbar-nav > li > ul > li > a:hover {
  background-color: transparent;
}
c-menu .c-menu__menu .navbar-nav .dropdown-item {
  white-space: unset;
}
c-menu .c-menu__menu .navbar-nav .dropdown-menu {
  position: static !important;
  transform: none !important;
}
c-menu .c-menu__menu .navbar-nav .dropdown-toggle {
  position: relative;
}
c-menu .c-menu__menu .navbar-nav .dropdown-toggle::after {
  font-size: 30px;
  font-weight: 700;
  border: 0;
  color: #fff;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s;
}
c-menu .c-menu__menu .navbar-nav .dropdown-toggle.show::after {
  transform: translateY(-50%) scaleY(-1);
}
c-menu .c-menu__menu .nav-item {
  opacity: 0;
}
c-menu .c-menu__close {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
c-menu .c-menu__outer {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
c-menu .c-menu__header {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
c-menu .c-menu__logolink {
  display: inline-block;
}
c-menu .c-menu__logo-img {
  display: block;
  height: 30px;
}
c-menu .c-menu__links {
  height: 100%;
  overflow-y: auto;
  font-size: 36px;
  line-height: 54px;
  -webkit-overflow-scrolling: touch;
}
c-menu .c-menu__links {
  background: linear-gradient(rgb(108, 40, 167) 30%, rgba(108, 40, 167, 0)) center top, linear-gradient(rgb(108, 40, 167), rgb(108, 40, 167) 70%) center bottom, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center top, radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}
c-menu .c-menu__footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
c-menu .c-menu__footer .button {
  min-width: 150px;
}

c-menu.show .c-menu__menu {
  pointer-events: auto;
}