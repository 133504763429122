:root {
  /* --color-blue: #1f2ef5; // ie --color-blue: #1f2ef5; */
  /* --color-coal: #0b1116; // ie --color-blue: #1f2ef5; */
  /* --color-pink: #f9e8d5; // ie --color-blue: #1f2ef5; */
  /* --color-black: #000; // ie --color-blue: #1f2ef5; */
  /* --color-white: #fff; // ie --color-blue: #1f2ef5; */
  /* --color-lightgray: #ceceee; // ie --color-blue: #1f2ef5; */
  /* --color-gray: #5b5765; // ie --color-blue: #1f2ef5; */
  /* --color-purple: #6c28a7; // ie --color-blue: #1f2ef5; */
  /* --color-yellow: #fab908; // ie --color-blue: #1f2ef5; */
  /* --color-red: #ff6b6c; // ie --color-blue: #1f2ef5; */
  /* --color-green: #66f285; // ie --color-blue: #1f2ef5; */
}

html.page-blog-details .section-gradient a {
  color: var(--color-primary);
}

.p-blogdetails {
  max-width: 800px;
  margin: 4rem auto 0;
}
.p-blogdetails > .widget--cta .w-cta > .container-xl {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-blogdetails__title {
  margin: 0 0 2rem;
  font-size: var(--h3-font-size);
  line-height: 37px;
}

.p-blogdetails__date,
.p-blogdetails__blogger {
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray);
  margin: 0 0 8px;
}

.p-blogdetails__blogger {
  text-align: right;
}

.p-blogdetails__image {
  margin: 0 0 0.5rem;
}
.p-blogdetails__image img {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.p-blogindex__cards {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  margin-top: 1rem !important;
}
.p-blogindex__cards .newscard__img {
  transition: transform 0.3s;
}
.p-blogindex__cards .newscard {
  width: auto;
  height: auto;
  display: block;
  aspect-ratio: 1;
}
@media (hover: hover) {
  .p-blogindex__cards .newscard:hover .newscard__img {
    transform: scale(1.1);
  }
}