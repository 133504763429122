:root {
  /* --color-blue: #1f2ef5; // ie --color-blue: #1f2ef5; */
  /* --color-coal: #0b1116; // ie --color-blue: #1f2ef5; */
  /* --color-pink: #f9e8d5; // ie --color-blue: #1f2ef5; */
  /* --color-black: #000; // ie --color-blue: #1f2ef5; */
  /* --color-white: #fff; // ie --color-blue: #1f2ef5; */
  /* --color-lightgray: #ceceee; // ie --color-blue: #1f2ef5; */
  /* --color-gray: #5b5765; // ie --color-blue: #1f2ef5; */
  /* --color-purple: #6c28a7; // ie --color-blue: #1f2ef5; */
  /* --color-yellow: #fab908; // ie --color-blue: #1f2ef5; */
  /* --color-red: #ff6b6c; // ie --color-blue: #1f2ef5; */
  /* --color-green: #66f285; // ie --color-blue: #1f2ef5; */
}

.w-vacancyform {
  background: var(--color-purple);
  padding: 40px 0 48px;
}
.w-vacancyform .wh-form__navbuttons {
  margin-top: 1.5rem;
}
.w-vacancyform .wh-form__richtext > * {
  margin-bottom: 0;
}
.w-vacancyform .quote {
  margin-top: 24px;
}

.w-vacancyform__text > *,
.w-vacancyform__col h2,
.w-vacancyform__col h3,
.wh-form__richtext > * {
  color: #fff !important;
}
.w-vacancyform__text > * a,
.w-vacancyform__col h2 a,
.w-vacancyform__col h3 a,
.wh-form__richtext > * a {
  color: var(--color-orange);
}

.w-vacancyform__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 64px;
  max-width: 1170px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .w-vacancyform {
    padding: 72px 0 100px 0;
  }
  .w-vacancyform__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}