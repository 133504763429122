/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-facts {
  margin-top: 24px;
}

.w-facts__grid {
  display: grid;
  flex-flow: row wrap;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;
}
@media (min-width: 992px) {
  .w-facts__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.w-facts__item-value {
  font-size: 40px;
  line-height: 100%;
  color: var(--color-orange);
  font-weight: 700;
  white-space: nowrap;
}

.w-facts__item-label {
  font-size: 20px;
  line-height: 100%;
  color: var(--color-text-black);
  font-weight: 500;
}