/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-ourmethod-slider {
  --splide-arrow-outer-size: 52px;
  --splide-arrow-size: 16px;
  background: linear-gradient(180deg, rgba(132, 64, 191, 0) 0%, rgba(132, 64, 191, 0.15) 100%);
  padding-bottom: 48px;
}
.w-ourmethod-slider .hero {
  background: #fff;
  color: #292233;
  min-height: auto;
}
.w-ourmethod-slider .c-step {
  position: relative;
  transition: opacity 0.3s ease-out;
}
.w-ourmethod-slider .c-step__imagecontainer {
  display: none;
}
.w-ourmethod-slider .c-step__title {
  font-size: 32px;
  line-height: 36px;
  font-weight: 900;
  border-bottom: 1px solid #292233;
  color: var(--color-primary) !important;
  margin-bottom: 24px;
  margin-top: 0.625rem;
  padding-bottom: 16px;
}
.w-ourmethod-slider .c-step__description {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .w-ourmethod-slider .c-step__description {
    font-size: 16px;
    line-height: 24px;
  }
}
.w-ourmethod-slider .c-step__number,
.w-ourmethod-slider .w-ourmethod-slider__pagenum {
  color: var(--color-orange);
  margin-bottom: 0;
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
}
.w-ourmethod-slider .c-step__number {
  display: none;
}
.w-ourmethod-slider .w-ourmethod-slider__pagenum {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.w-ourmethod-slider .splide__pagination {
  display: none;
  bottom: unset;
  position: relative;
  margin-top: 40px;
  grid-template-columns: repeat(4, 1fr);
  padding: 0;
  gap: 19px;
}
.w-ourmethod-slider li {
  outline: none;
}
.w-ourmethod-slider .splide__pagination__page {
  transform: none !important;
  height: 100%;
  width: 100%;
  border-radius: 0;
  background-color: #fff;
  opacity: 1;
  margin: 0;
  border-radius: 27.5px;
  border: 2px solid transparent;
  background: #fff;
  height: 50px;
  color: #263238;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  outline: none;
  padding: 0 1rem;
  transition: border 0.3s, background-color 0.3s, color 0.3s;
}
.w-ourmethod-slider .splide__pagination__page:focus {
  outline: none !important;
}
@media (hover: hover) {
  .w-ourmethod-slider .splide__pagination__page:hover {
    background-color: var(--color-purple);
    color: #fff;
  }
}
.w-ourmethod-slider .splide__pagination__page.is-active {
  outline: none;
  border-color: var(--color-purple);
}
.w-ourmethod-slider .splide__track {
  display: flex;
  flex-direction: column;
}
.w-ourmethod-slider .splide__arrows {
  position: relative;
  height: var(--splide-arrow-outer-size);
  order: -1;
  margin: 24px 0;
}
.w-ourmethod-slider .splide__arrow {
  background: var(--color-purple);
  width: var(--splide-arrow-outer-size);
  height: var(--splide-arrow-outer-size);
  opacity: 1;
  transition: opacity 0.3s, transform 0.3s, background-color 0.3s;
  transition-delay: 0;
  border: 2px solid var(--color-purple);
}
.w-ourmethod-slider .splide__arrow:focus-visible {
  outline: none;
}
.w-ourmethod-slider .splide__arrow:disabled {
  opacity: 0.3;
  pointer-events: none;
}
@media (hover: hover) {
  .w-ourmethod-slider .splide__arrow:hover {
    background-color: #fff;
  }
  .w-ourmethod-slider .splide__arrow:hover svg {
    fill: var(--color-purple);
  }
}
.w-ourmethod-slider .splide__arrow svg {
  transition: fill 0.3s;
  fill: #fff;
  width: var(--splide-arrow-size);
  height: var(--splide-arrow-size);
}
.w-ourmethod-slider .splide__arrow--prev {
  left: 0;
}
.w-ourmethod-slider .splide__arrow--next {
  right: 0;
}

@media (min-width: 768px) {
  .w-ourmethod-slider .c-step {
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 32px;
  }
  .w-ourmethod-slider .c-step__imagecontainer {
    display: flex;
  }
  .w-ourmethod-slider .c-step__image {
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 992px) {
  .w-ourmethod-slider {
    --splide-arrow-outer-size: 118px;
    --splide-arrow-size: 24px;
  }
  .w-ourmethod-slider .w-ourmethod-slider__heading {
    margin-bottom: 40px;
  }
  .w-ourmethod-slider .w-ourmethod-slider__pagenum {
    display: none !important;
  }
  .w-ourmethod-slider .c-step {
    grid-template-columns: 40% 1fr;
    gap: 48px;
  }
  .w-ourmethod-slider .c-step__number {
    display: block;
    margin-top: 32px;
  }
  .w-ourmethod-slider .c-step__image {
    aspect-ratio: 4/3;
    border-radius: 10px;
  }
  .w-ourmethod-slider .c-step__textcontainer {
    padding-right: calc(var(--splide-arrow-outer-size) + 40px);
  }
  .w-ourmethod-slider .c-step__title {
    border-bottom: 0;
    margin-bottom: 16px;
    padding-bottom: 0;
  }
  .w-ourmethod-slider .splide__arrows {
    position: static;
    height: unset;
    margin: 0;
  }
  .w-ourmethod-slider .splide__arrow:disabled {
    opacity: 0.1;
  }
  .w-ourmethod-slider .splide__pagination {
    display: grid;
  }
}