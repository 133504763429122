/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-team {
  display: grid;
  gap: 24px;
}
@media (min-width: 576px) {
  .w-team {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .w-team {
    grid-template-columns: repeat(4, 1fr);
  }
}
.w-team .w-team__image {
  transition: transform 0.3s;
}
.w-team .w-team__imagecontainer {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
@media (hover: hover) {
  .w-team .w-team__imagecontainer:hover .w-team__image {
    transform: scale(1.12);
  }
}
.w-team .w-team__info {
  position: absolute;
  z-index: 5;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 24px;
  color: #fff;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.w-team .w-team__name {
  color: #fff;
  font-weight: 900;
  font-size: 20px;
}
.w-team .w-team__position {
  color: var(--color-orange);
  font-weight: 700;
  font-size: 15px;
}
.w-team .w-team__quote {
  font-weight: 700;
  font-size: 15px;
  line-height: 160%;
  margin-top: 4px;
}
.w-team .w-team__imageoverlay {
  background: linear-gradient(180deg, rgba(33, 27, 41, 0) 26%, #211b29 89.75%);
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity 0.3s;
}