/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
:root {
  /* --color-blue: #1f2ef5; // ie --color-blue: #1f2ef5; */
  /* --color-coal: #0b1116; // ie --color-blue: #1f2ef5; */
  /* --color-pink: #f9e8d5; // ie --color-blue: #1f2ef5; */
  /* --color-black: #000; // ie --color-blue: #1f2ef5; */
  /* --color-white: #fff; // ie --color-blue: #1f2ef5; */
  /* --color-lightgray: #ceceee; // ie --color-blue: #1f2ef5; */
  /* --color-gray: #5b5765; // ie --color-blue: #1f2ef5; */
  /* --color-purple: #6c28a7; // ie --color-blue: #1f2ef5; */
  /* --color-yellow: #fab908; // ie --color-blue: #1f2ef5; */
  /* --color-red: #ff6b6c; // ie --color-blue: #1f2ef5; */
  /* --color-green: #66f285; // ie --color-blue: #1f2ef5; */
}

html.page-home {
  --tagline-max-width: 470px;
}

.homesubscribeform .c-steps {
  display: grid;
  position: relative;
  grid-template-columns: 100% 100%;
}
@media (max-width: 767.98px) {
  .homesubscribeform .c-steps {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
  .homesubscribeform .c-steps[data-step="0"] .c-step--1, .homesubscribeform .c-steps[data-step="1"] .c-step--0 {
    display: none;
  }
}
@media (min-width: 768px) {
  .homesubscribeform .c-steps {
    grid-template-columns: 100vw 100vw;
  }
}
.homesubscribeform .c-steps.skiplicenseform {
  margin-top: 1rem;
}

.homesubscribeform__headerpayoffcontainer {
  display: none;
}

.homesubscribeform__headerpayoffcontainer--mobile {
  display: block;
}

@media (min-width: 768px) {
  .homesubscribeform__headerpayoffcontainer--mobile {
    display: none;
  }
  .homesubscribeform__headerpayoffcontainer--desktop {
    display: unset;
  }
}
.homesubscribeform__headerpayoff {
  color: white !important;
  margin: 0rem 0 0.5rem 0;
  word-spacing: normal;
  font-size: 2rem;
  letter-spacing: normal;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .homesubscribeform__headerpayoff {
    margin-top: 1rem;
  }
}

.homesubscribeform__fbc {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .homesubscribeform__fbc {
    margin-bottom: 3rem;
  }
}
@media (min-width: 1116px) {
  .homesubscribeform__fbc {
    display: none;
  }
}

.homesubscribeform__nolicense {
  margin-top: 16px;
  color: #fff !important;
}

.homesubscribeform__container {
  padding-top: 2rem;
}
@media (max-width: 767.98px) {
  .homesubscribeform__container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.homesubscribeform__container[data-step="1"] {
  padding-top: 0;
}
@media (min-width: 768px) {
  .homesubscribeform__container[data-step="1"] {
    padding-top: 2rem;
  }
}

.input__license input {
  width: 100%;
}

.homesubscribeform h1 .mobile-only {
  display: initial;
  text-transform: capitalize;
  line-height: 56px;
}
.homesubscribeform h1 .desktop-only {
  display: none;
}
@media (min-width: 768px) {
  .homesubscribeform h1 .mobile-only {
    display: none;
  }
  .homesubscribeform h1 .desktop-only {
    display: initial;
  }
}

.homesubscribeform .button--go {
  position: relative;
  height: 61px;
}

.homesubscribeform__nolicense.disabled,
.homesubscribeform__license-input-wrapper.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.homesubscribeform__container {
  position: relative;
  overflow: hidden;
}
.homesubscribeform__content {
  position: relative;
  z-index: 1;
  text-align: start;
  padding: 0;
}
.homesubscribeform__content a {
  color: #fff !important;
}
@media (min-width: 768px) {
  .homesubscribeform__content {
    padding: 0 var(--logo-padding-x);
  }
}
@media (max-width: 768px) {
  .homesubscribeform__content {
    margin: 0 auto;
  }
  .homesubscribeform__content .steptitle {
    padding-top: 10px;
  }
  .homesubscribeform__content .steptitle--step1 {
    padding-top: 0;
  }
}

.homesubscribeform__introtitle-default {
  display: block;
  max-width: 540px;
}

.homesubscribeform__intro h1,
.homesubscribeform__intro .h1 {
  margin-bottom: 16px;
  font-size: 56px;
  line-height: 56px;
  font-weight: 900;
}
.homesubscribeform__intro h1.homesubscribeform__introtitle--fromcms,
.homesubscribeform__intro .h1.homesubscribeform__introtitle--fromcms {
  white-space: pre-line;
}
@media (min-width: 768px) {
  .homesubscribeform__intro h1,
  .homesubscribeform__intro .h1 {
    font-size: 88px;
    line-height: 88px;
  }
}
.homesubscribeform__intro .tagline {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  max-width: var(--tagline-max-width);
}
@media (min-width: 768px) {
  .homesubscribeform__introtitle--setfromurl {
    max-width: 50vw;
  }
}
@media (min-width: 1200px) {
  .homesubscribeform__introtitle--setfromurl {
    max-width: 600px;
  }
}

.backbuttoncontainer {
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .backbuttoncontainer .back-button {
    top: -25px;
  }
}

.homesubscribeform__buttons {
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}

@media (min-width: 768px) {
  .homesubscribeform__buttons {
    flex-direction: row;
    justify-content: flex-start;
  }
}
.homesubscribeform__button {
  min-width: 142px;
  color: #fff !important;
  display: inline-flex;
  gap: 8px;
}
.homesubscribeform__button img {
  display: none;
  width: 32px;
  height: 32px;
}
.homesubscribeform__button.homesubscribeform__button--car img, .homesubscribeform__button.homesubscribeform__button--motor img {
  width: 36px;
  height: 36px;
}

.homesubscribeform__buttons--showicons .homesubscribeform__button img {
  display: block !important;
}

.homesubscribeform__tikkieconditions {
  margin-left: 2rem;
}

.homesubscribeform__image {
  display: none;
  position: absolute;
  max-width: 243px;
  right: 0;
  bottom: 0;
  transform: translate(10%, 30%);
}
@media (min-width: 768px) {
  .homesubscribeform__image {
    max-width: 330px;
    transform: translateX(calc(180px + var(--tagline-max-width)));
    top: -5px;
    right: auto;
    left: 0;
    display: block;
  }
}
.homesubscribeform__image {
  display: none;
}

@media (max-width: 767.98px) {
  .homesubscribeform__image {
    display: block;
    position: relative;
    max-width: unset;
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
  }
  .homesubscribeform__image img {
    width: 40%;
    margin: 0 auto;
  }
  html.page-home .pagecontainer.homesubscribeform,
  body .pagecontainer.homesubscribeform {
    padding: 32px 0 0 !important;
  }
}
#home-subscribe-form-step-license {
  display: flex;
  margin-top: 6rem;
  gap: 16px;
  justify-content: flex-start;
  margin-top: 27px;
}
@media (max-width: 370px) {
  #home-subscribe-form-step-license {
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 768px) {
  #home-subscribe-form-step-license {
    gap: 16px;
    align-items: start;
    margin-top: 40px;
  }
}

.homesubscribeform__introtitle-tikkie {
  display: none;
}

.homesubscribeform__tikkielogo {
  display: none;
  margin-bottom: 1rem;
}
.homesubscribeform__tikkielogo img {
  width: auto;
  height: 44px;
}
.homesubscribeform__tikkielogo a {
  display: flex;
}

.js-tikkie-conditions-link {
  display: none !important;
}

html[data-is-tikkie=true] .js-tikkie-conditions-link {
  display: inline !important;
}
html[data-is-tikkie=true] .homesubscribeform__image {
  display: none;
}
html[data-is-tikkie=true] .homesubscribeform__nolicense,
html[data-is-tikkie=true] .licenseform__nolicense {
  opacity: 1;
}
html[data-is-tikkie=true] .homesubscribeform__introtitle-default {
  display: none;
}
html[data-is-tikkie=true] .homesubscribeform__introtitle-tikkie {
  display: inline-block;
  max-width: 900px;
}
html[data-is-tikkie=true] .homesubscribeform__tikkielogo {
  display: inline-block;
}
@media (max-width: 767.98px) {
  html[data-is-tikkie=true] .homesubscribeform__container {
    padding-top: 0;
  }
  html[data-is-tikkie=true] .homesubscribeform__introtitle-tikkie {
    font-size: 38px;
    line-height: 120%;
  }
  html[data-is-tikkie=true] .c-steps .steptitle {
    padding-top: 0;
  }
}
html[data-is-tikkie=true] .homesubscribeform__introtitle {
  margin-bottom: 0.2em;
}
@media (min-width: 768px) {
  html[data-is-tikkie=true] .homesubscribeform__container {
    padding-bottom: 100px;
  }
}